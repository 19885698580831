import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit"
import {intersection, isEqual} from "lodash"
import {APIStatus} from "../types/APITypes"
import {Portal, PortalRoute, PortalsPanelItems} from "../types/securityTypes"
import {AuthPerson, AuthResponse, LoginResponse} from "../api/authAPI"
import {IUserAppSettings} from "../types/settingsTypes"
import {createSelectorCreator, defaultMemoize} from "reselect"
import {HeaderAlertEntity} from "../api/alertsAPI"
import {DateFormat, TimeFormat} from "../types/userSettingsTypes"
import {PhoneEntity} from "../features/PhoneInput/types"

export enum NetworkType {
    SCHOOL = "SCHOOL",
    SYSTEM = "SYSTEM",
    BUSINESS = "BUSINESS",
    PROVIDER = "PROVIDER",
    STUDENT = "STUDENT",
    HIGHER_ED = "HIGHER_ED",
}

export type PersonLocale = {
    id: string
    name: string
}

export interface AuthSliceState {
    person?: {
        email: string
        phone?: PhoneEntity
        authorities: string[]
        roleAuthorities: {[p in keyof AuthPerson["roleAuthorities"]]?: string[]}
        id: number
        fullName?: string
        portals?: Portal[]
        portalsPanelItems: PortalsPanelItems
        school?: number
        district?: number
        businessName?: string
        settings?: IUserAppSettings
        alertusToken?: string
        isSchoolUser?: boolean
        isHigherEdUser?: boolean
        isBusinessUser?: boolean
        isSupportUser?: boolean
        networkTypes?: Array<NetworkType>
        getDisableLiveNotifications?: boolean
        locale?: PersonLocale
        noDevicesTrial?: boolean
        dateFormat?: DateFormat
        timeFormat?: TimeFormat
    }
    currentPortal?: Portal

    locations?: {
        schools: Array<HeaderAlertEntity>
        districts: Array<HeaderAlertEntity>
        businesses: Array<HeaderAlertEntity>
        buildings: Array<HeaderAlertEntity>
        heBuildings: Array<HeaderAlertEntity>
        heDistricts: Array<HeaderAlertEntity>
    }
    vacantDevices?: {
        vacantDeviceCount: number
        businessVacantDeviceCount: number
        higherEdVacantDeviceCount: number
    }

    isShowCertificates?: boolean
    accessToken?: string
    accessTokenETag?: string
    accessTokenETagInvalid?: boolean

    accessAllowed?: boolean
    homePortalURL?: string

    loginStatus: APIStatus
    logoutStatus: APIStatus
    authStatus: APIStatus
    savePasswordStatus: APIStatus
    incorrectLoginData: boolean

    initialEmail?: string
    nonConfirmedAccount?: boolean
    request2fa?: boolean
    requestTOSAgreement?: boolean
    loginRetryPayload?: {
        email: string
        password: string
    }

    error?: string

    globalUIState: {
        menuNavigationOpen: boolean
        profileMenuOpen: boolean
        portalSectionMenuOpen: boolean
        notificationMenuOpen: boolean
    }
}

export const authInitialState: AuthSliceState = {
    loginStatus: APIStatus.Initial,
    logoutStatus: APIStatus.Initial,
    authStatus: APIStatus.Initial,
    savePasswordStatus: APIStatus.Initial,
    incorrectLoginData: false,
    globalUIState: {
        menuNavigationOpen: false,
        profileMenuOpen: false,
        portalSectionMenuOpen: false,
        notificationMenuOpen: false,
    },
}

export const authSlice = createSlice<
    AuthSliceState,
    {
        startLogin: (state: AuthSliceState) => void
        successLogin: (state: AuthSliceState, action: PayloadAction<LoginResponse>) => void
        failLogin: (
            state: AuthSliceState,
            action: PayloadAction<{status?: number; message: string}>
        ) => void
        startLogout: (state: AuthSliceState) => void
        finishLogout: (state: AuthSliceState) => void
        updateUserData: (
            state: AuthSliceState,
            action: PayloadAction<{fullName: string; email: string}>
        ) => void
        updateUserPhone: (
            state: AuthSliceState,
            action: PayloadAction<{phone: PhoneEntity}>
        ) => void
        setPersonAuthorities: (state: AuthSliceState, action: PayloadAction<string[]>) => void
        startAuth: (state: AuthSliceState) => void
        failAuth: (state: AuthSliceState) => void
        successAuth: (
            state: AuthSliceState,
            action: PayloadAction<AuthResponse & {etag: string; portal: Portal}>
        ) => void
        invalidateAuth: (state: AuthSliceState) => void
        updateAccessData: (
            state: AuthSliceState,
            action: PayloadAction<{currentPortal: Portal}>
        ) => void
        startPasswordSave: (state: AuthSliceState) => void
        failPasswordSave: (state: AuthSliceState) => void
        successPasswordSave: (state: AuthSliceState) => void
        setInitialEmail: (state: AuthSliceState, action: PayloadAction<string>) => void
        setNonConfirmedAccount: (state: AuthSliceState, action: PayloadAction<boolean>) => void
        setError: (state: AuthSliceState, action: PayloadAction<string>) => void
        reset: (state: AuthSliceState) => void
        setMenuNavigationState: (state: AuthSliceState, action: PayloadAction<boolean>) => void
        setProfileMenuOpen: (state: AuthSliceState, action: PayloadAction<boolean>) => void
        setNotificationMenuState: (state: AuthSliceState, action: PayloadAction<boolean>) => void
        setPortalSectionMenuState: (state: AuthSliceState, action: PayloadAction<boolean>) => void
        resetGlobalUI: (state: AuthSliceState, action: PayloadAction) => void
        setVacantDevices: (
            state: AuthSliceState,
            action: PayloadAction<AuthSliceState["vacantDevices"]>
        ) => void
        setLocations: (
            state: AuthSliceState,
            action: PayloadAction<AuthSliceState["locations"]>
        ) => void
        dehydrate: (state: AuthSliceState, action: PayloadAction<AuthSliceState>) => void
        setRequestTOSAgreement: (
            state: AuthSliceState,
            action: PayloadAction<{
                requestTOSAgreement: boolean
                loginRetryPayload?: AuthSliceState["loginRetryPayload"]
            }>
        ) => void
        setPersonLocale: (state: AuthSliceState, action: PayloadAction<PersonLocale>) => void
        resetVerificationWarnings: (state: AuthSliceState) => void
    }
>({
    name: "auth",
    initialState: authInitialState,
    reducers: {
        startLogin(state: AuthSliceState) {
            state.loginStatus = APIStatus.Loading
        },
        successLogin(state: AuthSliceState, action: PayloadAction<LoginResponse>) {
            state.loginStatus = APIStatus.Success
            state.person = {
                id: action.payload.id,
                roleAuthorities: action.payload.roleAuthorities,
                authorities: action.payload.authorities,
                email: action.payload.email,
                phone: action.payload.phone,
                portalsPanelItems: {},
            }
            state.accessToken = action.payload.access_token
            state.nonConfirmedAccount = action.payload.nonConfirmedAccount
            state.incorrectLoginData = false
            state.error = ""
            state.requestTOSAgreement = action.payload.requestTOSAgreement

            if (state.requestTOSAgreement) {
                state.loginRetryPayload = action.payload.request
            } else {
                state.loginRetryPayload = undefined
            }

            if (action.payload.request2fa && state.request2fa) {
                state.error = "Verification code is incorrect"
            }
            if (state.request2fa && !action.payload.request2fa) {
                // Stay on 2fa page while waiting for /home redirect
                state.request2fa = true
            } else {
                state.request2fa = action.payload.request2fa
            }
        },
        failLogin(
            state: AuthSliceState,
            action: PayloadAction<{status?: number; message: string}>
        ) {
            if (action.payload.status === 403) {
                state.incorrectLoginData = true
                state.error = "Wrong email or password"
            }
            if (action.payload.status !== 502) {
                state.loginStatus = APIStatus.Failure
            } else {
                state.loginStatus = APIStatus.Initial
            }
        },

        startLogout(state: AuthSliceState) {
            state.logoutStatus = APIStatus.Loading
            state.person = undefined
            state.accessToken = undefined
            state.accessAllowed = undefined
            state.homePortalURL = undefined
        },
        finishLogout() {
            return authInitialState
        },

        updateUserData(state, action) {
            const {fullName, email} = action.payload

            state.person!.email = email
            state.person!.fullName = fullName
        },
        updateUserPhone(state, action) {
            state.person!.phone = action.payload.phone
        },
        setPersonAuthorities(state, action) {
            if (state.person) {
                state.person.authorities = action.payload
            }
        },

        startAuth(state) {
            state.authStatus = APIStatus.Loading
        },
        failAuth(state) {
            state.authStatus = APIStatus.Failure
        },
        successAuth(state, action) {
            if (window.IS_PLAYWRIGHT) {
                window.authorities = action.payload.person.authorities
            }

            const personPortals = action.payload.person.portals
            const currentPortal = action.payload.portal
            const accessAllowed =
                !!personPortals?.includes(currentPortal) ||
                currentPortal === Portal.Creative ||
                currentPortal === Portal.Auth
            if (!action.payload.person.portalsPanelItems) {
                action.payload.person.portalsPanelItems = []
            }

            return {
                ...state,
                currentPortal,
                person: action.payload.person,
                accessToken: action.payload.token,
                accessTokenETag: action.payload.etag,
                homePortalURL: !accessAllowed ? PortalRoute[Portal.Home] : undefined,
                authStatus: APIStatus.Success,
                accessAllowed,
                isShowCertificates: action.payload.person.isShowCertificates,
            }
        },
        setPersonLocale(state, action) {
            if (state.person) {
                state.person.locale = action.payload
            }
        },
        setLocations(state, action) {
            state.locations = action.payload
        },
        setVacantDevices(state, action) {
            state.vacantDevices = action.payload
        },
        invalidateAuth(state) {
            return {
                ...state,
                accessTokenETagInvalid: true,
            }
        },
        updateAccessData(state, action) {
            const personPortals = state.person?.portals
            const currentPortal = action.payload.currentPortal
            const accessAllowed = !!personPortals?.includes(currentPortal)

            state.homePortalURL = !accessAllowed ? PortalRoute[Portal.Home] : undefined
            state.accessAllowed = accessAllowed
        },

        startPasswordSave(state) {
            state.savePasswordStatus = APIStatus.Loading
        },
        failPasswordSave(state) {
            state.savePasswordStatus = APIStatus.Failure
        },
        successPasswordSave(state) {
            state.savePasswordStatus = APIStatus.Success
        },
        setInitialEmail(state, action) {
            state.initialEmail = action.payload
        },
        setNonConfirmedAccount(state, action) {
            state.nonConfirmedAccount = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },

        setMenuNavigationState(state, action) {
            state.globalUIState.menuNavigationOpen = action.payload
        },
        setProfileMenuOpen(state, action) {
            state.globalUIState.profileMenuOpen = action.payload
        },
        setNotificationMenuState(state, action) {
            state.globalUIState.notificationMenuOpen = action.payload
        },
        setPortalSectionMenuState(state, action) {
            state.globalUIState.portalSectionMenuOpen = action.payload
        },
        resetGlobalUI(state) {
            return {...state, globalUIState: authInitialState.globalUIState}
        },
        setRequestTOSAgreement(state, action) {
            const {loginRetryPayload, requestTOSAgreement} = action.payload
            state.requestTOSAgreement = requestTOSAgreement
            state.loginRetryPayload = loginRetryPayload
        },
        resetVerificationWarnings(state) {
            state.nonConfirmedAccount = false
            state.loginStatus = APIStatus.Initial
        },
        reset(state) {
            state.request2fa = undefined
            state.error = undefined
        },
        dehydrate(state, action) {
            if (state.person) return state
            return action.payload
        },
    },
})

export const checkAuthorities: (
    userAuthorities: Array<string>,
    authorities: Array<string>
) => boolean = (userAuthorities: Array<string>, authorities: Array<string> = []) => {
    if (!authorities.length) {
        return false
    }
    return intersection(userAuthorities, authorities).length !== 0
}

export interface AuthStore {
    auth: AuthSliceState
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const authSelectors = {
    getHydrationData: createSelector(
        (store: AuthStore) => store.auth,
        (store: AuthStore) => store.auth.currentPortal,
        (hydrationData, currentPortal) =>
            hydrationData && currentPortal ? {hydrationData, currentPortal} : undefined
    ),
    getLoginStatus: (store: AuthStore) => store.auth.loginStatus,
    getId: (store: AuthStore) => store.auth.person?.id,
    getDisableLiveNotifications: (store: AuthStore) =>
        store.auth.person?.getDisableLiveNotifications,
    getEmail: (store: AuthStore) => store.auth.person?.email,
    getPhone: (store: AuthStore) => store.auth.person?.phone,
    getPersonRoleAuthorities: (store: AuthStore) => store.auth.person?.roleAuthorities,

    getToken: createSelector(
        (store: AuthStore) => store.auth.accessToken,
        (store: AuthStore) => store.auth.accessTokenETag,
        (accessToken, accessTokenETag) => [accessToken, accessTokenETag]
    ),
    getAuthValues: createSelector(
        (store: AuthStore) => store.auth.authStatus,
        (store: AuthStore) => store.auth.accessTokenETagInvalid,
        (status, invalid) => ({
            status,
            invalid,
        })
    ),
    getAuthenticated: (store: AuthStore) => !!store.auth.accessToken,
    getAuthorities: (store: AuthStore) => store.auth.person?.authorities,
    getAuthedUserId: (store: AuthStore) => store.auth.person?.id,
    getPortals: (store: AuthStore) => store.auth.person?.portals,
    getPortalsPanelItems: (store: AuthStore) => store.auth.person?.portalsPanelItems,

    getUserData: (store: AuthStore) => store.auth.person,
    getSchool: (store: AuthStore) => store.auth.person?.school,
    getDistrict: (store: AuthStore) => store.auth.person?.district,
    getFullName: (store: AuthStore) => store.auth.person?.fullName,
    getBusinessName: (store: AuthStore) => store.auth.person?.businessName,
    getAppSettings: (store: AuthStore) => store.auth.person?.settings,
    getNoDevicesTrial: (store: AuthStore) => store.auth.person?.noDevicesTrial,
    getTimeFormat: (store: AuthStore) => store.auth.person?.timeFormat,
    getDateFormat: (store: AuthStore) => store.auth.person?.dateFormat,

    getRoleAuthorities: createDeepEqualSelector(
        (state: AuthStore) => state.auth.person?.roleAuthorities,
        (state: AuthStore) => state.auth.person?.authorities,
        (state, role: keyof AuthPerson["roleAuthorities"]) => role,
        (roleAuthorities, authorities, role) => {
            if (roleAuthorities && Object.keys(roleAuthorities).length) {
                return roleAuthorities[role as keyof AuthPerson["roleAuthorities"]] || []
            }
            return authorities || []
        }
    ),

    getUserHasAuthorities: createDeepEqualSelector(
        (state: AuthStore) => state.auth.person?.authorities,
        (state, authorities: string[]) => authorities,
        (userAuthorities, authorities) => {
            return checkAuthorities((userAuthorities as string[]) || [], authorities as string[])
        }
    ),

    getUserHasRoleAuthorities: createDeepEqualSelector(
        (state: AuthStore) => state.auth.person?.roleAuthorities,
        (state: AuthStore) => state.auth.person?.authorities,
        (state, role: keyof AuthPerson["roleAuthorities"], authorities?: string[]) => ({
            role,
            authorities,
        }),
        (roleAuthorities = {}, userAuthorities, obj) => {
            const {role, authorities = []} = obj as {
                role: keyof AuthPerson["roleAuthorities"]
                authorities?: string[]
            }
            const uAuth: string[] =
                roleAuthorities && Object.keys(roleAuthorities).length
                    ? roleAuthorities[role]
                    : userAuthorities
            return checkAuthorities(uAuth || [], authorities)
        }
    ),

    getAccessAllowed: (store: AuthStore) => store.auth.accessAllowed,
    getHomePortalURL: (store: AuthStore) => store.auth.homePortalURL,

    getAuthStatus: (store: AuthStore) => store.auth.authStatus,
    getSavePasswordStatus: (store: AuthStore) => store.auth.savePasswordStatus,

    getLogoutStatus: (store: AuthStore) => store.auth.logoutStatus,

    getUserSchools: (store: AuthStore) => store.auth.locations?.schools,
    getUserDistricts: (store: AuthStore) => store.auth.locations?.districts,
    getUserBusinesses: (store: AuthStore) => store.auth.locations?.businesses,
    getUserBuildings: (store: AuthStore) => store.auth.locations?.buildings,
    getUserHEDistricts: (store: AuthStore) => store.auth.locations?.heDistricts,
    getUserHEBuildings: (store: AuthStore) => store.auth.locations?.heBuildings,
    getUserLocations: (store: AuthStore) => store.auth.locations,

    getVacantDeviceCount: (store: AuthStore) => store.auth.vacantDevices?.vacantDeviceCount,
    getBusinessVacantDeviceCount: (store: AuthStore) =>
        store.auth.vacantDevices?.businessVacantDeviceCount,
    getHigherEdVacantDeviceCount: (store: AuthStore) =>
        store.auth.vacantDevices?.higherEdVacantDeviceCount,

    getAlertusToken: (store: AuthStore) => store.auth.person?.alertusToken,
    getIsShowCertificates: (store: AuthStore) => store.auth.isShowCertificates,

    getInitialEmail: (store: AuthStore) => store.auth.initialEmail,
    getNonConfirmedAccount: (store: AuthStore) => store.auth.nonConfirmedAccount,
    getRequest2fa: (store: AuthStore) => store.auth.request2fa,
    getRequestTOSAgreement: (store: AuthStore) => store.auth.requestTOSAgreement,
    getLoginRetryPayload: (store: AuthStore) => store.auth.loginRetryPayload,

    getError: (store: AuthStore) => store.auth.error,
    getIsSchoolUser: (store: AuthStore) => store.auth.person?.isSchoolUser,
    getIsHigherEdUser: (store: AuthStore) => store.auth.person?.isHigherEdUser,
    getIsBusinessUser: (store: AuthStore) => store.auth.person?.isBusinessUser,
    getIsSupportUser: (store: AuthStore) => store.auth.person?.isSupportUser,
    getNetworkTypes: (store: AuthStore) => store.auth.person?.networkTypes,
    getLocale: (store: AuthStore) => store.auth.person?.locale,

    getGlobalUIState: (state: AuthStore) => state.auth.globalUIState,
}
